import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { withStyles } from "@material-ui/core"
import Helmet from 'react-helmet';

const styles = theme => ({
  bullets: {
    listStyleType: "none",
    margin: "0 0 0 24px",
    padding: 0,
  },
  bullet: {
    background: "url(/images/tick.gif) no-repeat center left",
    margin: 0,
    padding: "4px 0 4px 24px",
  },
  image: {
    float: "right",
  },
  [theme.breakpoints.down("xs")]: {
    image: {
      display: "none",
    },
  },
})

function ServiceTemplate({ data, classes }) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title} - QHFS Limited</title>
      </Helmet>
      <img src={frontmatter.image} className={classes.image} alt=""/>
      <h2>{frontmatter.title}</h2>
      {frontmatter.bullets &&
        <ul className={classes.bullets}>
          {frontmatter.bullets.map((value) => {
            return <li className={classes.bullet}>{value}</li>
          })}
        </ul>
      }
      <div dangerouslySetInnerHTML={{ __html: html }}/>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      html
      frontmatter {
        path
        title
        image
        bullets
      }
    }
  }
`

ServiceTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ServiceTemplate)